import installScript from "../store/installScript";
import installCSSFile from "./installCSSFile";
import {installTPScript} from "../render/renderRawContent";

const installDriveServiceScripts = (client) => {
    const CouponWidgetLists = document.getElementsByClassName(
        "ds-coupon-widget-list-row"
    );
    const CouponSwipers = document.getElementsByClassName(
        "ds-coupon-swiper-row"
    );
    const DetailRows = document.getElementsByClassName("ds-coupon-detail-row");
    if (
        CouponWidgetLists.length > 0 ||
        CouponSwipers.length > 0 ||
        DetailRows.length > 0
    ) {
        installScript("FODStore.js", "ds-specials-store-script");
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "DriveServiceDeviceDetect.js",
            "ds-specials-v5-device-detect-script"
        );
        installScript("ReactSlick.js", "ds-specials-v5-react-slick-script");
        installCSSFile("ReactSlick.css", "ds-specials-v5-react-slick-styles");
        installScript(
            "CouponWidgets.js",
            "ds-specials-v5-coupon-widget-script"
        );
    }

    const BannerSwipers = document.getElementsByClassName(
        "ds-banner-swiper-row"
    );
    if (BannerSwipers.length > 0) {
        installScript("FODStore.js", "ds-specials-store-script");
        installCSSFile("ReactSlick.css", "ds-specials-v5-react-slick-styles");
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "DriveServiceDeviceDetect.js",
            "ds-specials-v5-device-detect-script"
        );
        installScript("ReactSlick.js", "ds-specials-v5-react-slick-script");
        installScript("BannerSwiper.js", "ds-specials-v5-banner-swiper-script");
    }
    const InternalNavbars = document.getElementsByClassName(
        "ds-internal-navbar-row"
    );
    if (InternalNavbars.length > 0) {
        installScript("FODStore.js", "ds-specials-store-script");
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "InternalNavbar.js",
            "ds-specials-v5-internal-navbar-script"
        );
    }
    const HybridSwipers = document.getElementsByClassName(
        "ds-hybrid-swiper-row"
    );
    if (HybridSwipers.length > 0) {
        installScript("FODStore.js", "ds-specials-store-script");
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "DriveServiceDeviceDetect.js",
            "ds-specials-v5-device-detect-script"
        );
        installCSSFile("ReactSlick.css", "ds-specials-v5-react-slick-styles");
        installScript("HybridSwiper.js", "ds-specials-v5-hybrid-swiper-script");
        installScript("ReactSlick.js", "ds-specials-v5-react-slick-script");
    }
    const ImageGalleries = document.getElementsByClassName(
        "ds-image-gallery-row"
    );
    if (ImageGalleries.length > 0) {
        installScript("FODStore.js", "ds-specials-store-script");
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript("ImageGallery.js", "ds-specials-v5-image-gallery-script");
    }

    const Amenities = document.getElementsByClassName(
        "ds-amenities-section-row"
    );
    if (Amenities.length > 0) {
        installScript("FODStore.js", "ds-specials-store-script");
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "Amenities.js",
            "ds-specials-v5-amenities-section-script"
        );
    }
    const MultiColumnRows = document.getElementsByClassName(
        "ds-multiple-columns-row"
    );
    if (MultiColumnRows.length > 0) {
        installScript("FODStore.js", "ds-specials-store-script");
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "DriveServiceDeviceDetect.js",
            "ds-specials-v5-device-detect-script"
        );
        installScript("MultiColumn.js", "ds-specials-v5-multi-column-script");
    }
    const CustomCSSRows = document.getElementsByClassName("ds-custom-css-row");
    if (CustomCSSRows.length > 0) {
        installScript("CustomCSSRow.js", "ds-specials-v5-custom-css");
    }
    const MapSectionRows =
        document.getElementsByClassName("ds-map-section-row");
    if (MapSectionRows.length) {
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "DriveServiceDeviceDetect.js",
            "ds-specials-v5-device-detect-script"
        );
        installScript("MapSection.js", "ds-specials-map-section-script");
    }
    const AnchorRows = document.getElementsByClassName("ds-anchor-row");
    if (AnchorRows.length > 0) {
        installScript("FODStore.js", "ds-specials-store-script");
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "DriveServiceDeviceDetect.js",
            "ds-specials-v5-device-detect-script"
        );
        installScript("AnchorRow.js", "ds-specials-anchor-row-script");
    }

    const RecallWidgetRows = document.getElementsByClassName(
        "ds-recall-widget-row"
    );
    if (RecallWidgetRows.length > 0) {
        installScript("FODStore.js", "ds-specials-store-script");
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "DriveServiceDeviceDetect.js",
            "ds-specials-v5-device-detect-script"
        );
        installScript(
            "RecallWidget.js",
            "ds-specials-recall-widget-row-script"
        );
    }

    const SunbitModals = document.getElementsByClassName("dss-sunbit-modal");
    if (SunbitModals.length > 0) {
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "DriveServiceDeviceDetect.js",
            "ds-specials-v5-device-detect-script"
        );
        installScript("SunbitModal.js", "ds-specials-sunbit-modal-script");
    }

    const DignifiModals = document.getElementsByClassName("dss-dignifi-modal");
    if (DignifiModals.length > 0) {
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript(
            "DriveServiceDeviceDetect.js",
            "ds-specials-v5-device-detect-script"
        );
        installScript("DignifiModal.js", "ds-specials-dignifi-modal-script");
    }

    const ShareModals = document.getElementsByClassName("dss-share-modal");
    if (ShareModals.length > 0) {
        installScript("DriveServiceReact.js", "ds-specials-v5-react-script");
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript("ShareModal.js", "ds-specials-share-modal-script");
    }

    const VideoRows = document.getElementsByClassName("ds-video-row");
    if (VideoRows.length > 0) {
        installScript("VideoRow.js", "ds-specials-video-row-script");
    }

    const ContentBlocks = document.getElementsByClassName(
        "ds-content-block-row"
    );
    if (ContentBlocks.length > 0) {
        installScript(
            "DriveServiceStyledComponents.js",
            "ds-specials-v5-styled-components-script"
        );
        installScript("ContentBlock.js", "ds-specials-v5-content-block-script");
    }

    const FooterRows = document.getElementsByClassName("ds-branded-footer-row");
    if (FooterRows.length > 0) {
        installScript("BrandedFooter.js", "ds-specials-v5-footer-row-script");
    }

    const ValueWatchRows = document.getElementsByClassName("tp-so-value-tracker-row");
    if(ValueWatchRows.length > 0) {
        if(!window.TradePendingPlugin) {
            console.log("TradePending script not found, installing now...", window.TradePendingPlugin)
            installTPScript(client);
        } else {
            console.log("TradePending script found, setting up now...")
            window.tradependingSetup();
        }
    }
};

export default installDriveServiceScripts;
